<template>
  <div class="footer">
    <!-- <div class="top">
      <div class="link">相关链接：<a href="">北京商业机械研究所官网</a></div>
    </div> -->
    <div class="bottom">
      <div class="bottom-container">
        <div class="link-box">
          <div class="link-title">解决方案</div>
          <div class="link-item"><a href="">供应链生产监管平台</a></div>
          <div class="link-item"><a href="">供应链贸易平台</a></div>
          <div class="link-item"><a href="">供应链大数据平台</a></div>
        </div>
        <div class="link-box">
          <div class="link-title">服务与支持</div>
          <div class="link-item"><a href="">版本更新</a></div>
          <div class="link-item"><a href="">登录系统</a></div>
          <div class="link-item"><a href="">咨询产品</a></div>
        </div>
        <div class="link-box">
          <div class="link-title">帮助中心</div>
          <div class="link-item"><a href="">版本更新</a></div>
          <div class="link-item"><a href="">登录系统</a></div>
          <div class="link-item"><a href="">咨询产品</a></div>
        </div>
        <div class="link-box">
          <div class="link-title">正式版</div>
          <div>
            <img src="@/assets/img/mobile.png" alt="" class="ErWeiMa">
          </div>
        </div>
        <div class="link-box">
          <div class="link-title">测试版</div>
          <div>
            <img src="@/assets/img/tmy-test.png" alt="" class="ErWeiMa">
          </div>
        </div>
        <div class="link-box">
          <div class="link-title">供应商端</div>
        </div>
      </div>
      <div class="copyright">
        中华全国供销合作总社北京商业机械研究所 ©版权所有
        ICP备案：<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备15064683号-6</a>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "FooterContainer",
  data() {
    return {
      
    }
  },
}
</script>

<style lang='less' scoped>
.footer {
  width: 100%;
  .top {
    height: 90px;
    left: 90px;
    background-color: #328dff;
    .link {
      background-color: #328dff;
      width: 1220px;
      height: 100%;
      line-height: 90px;
      margin: 0 auto;
      font-size: 20px;
      color: #fff;
      a {
        font-size: 20px;
        color: #fff;
        text-decoration:none;
      }
    }
  }
  .bottom {
    background-color: #282828;
    // padding: 60px 0;
    .bottom-container {
      padding: 60px 0;
      background-color: #282828;
      width: 1220px;
      margin: 0 auto;
      display: flex;
      color: #fff;
      .link-box {
        width: 200px;
        display: flex;
        flex-direction: column;
        .link-title {
          font-size: 20px;
          margin-bottom: 30px;
        }
        .link-item {
          line-height: 36px;
          a {
            color: #fff;
            font-size: 16px;
            text-decoration: none;
          }
        }
      }
    }
    .copyright {
      width: 1220px;
      margin: 0 auto;
      padding-bottom: 60px;
      background-color: #282828;
      text-align: center;
      color: #fff;
      font-size: 14px;
      a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;
      }
    }
  }

  .ErWeiMa{
    width: 100px;
    height: 100px;
  }
}
</style>
