<template>
  <div id="app">
    <header-container></header-container>

    <router-view></router-view>

    <footer-container></footer-container>
  </div>
</template>

<script>
import HeaderContainer from './components/common/HeaderContainer.vue';
import FooterContainer from './components/common/FooterContainer.vue';

export default {
  name: 'App',
  components: {
    HeaderContainer,
    FooterContainer
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  
}
</style>
