import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = ()=>import('../views/home/Home.vue')

const routes = [
  {
    path: "",
    redirect: "/home"
  },
  {
    path: "/home",
    component: Home,
    meta:{
      title:'供应链管理平台'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(to=>{
  document.title = to.meta.title;
})

export default router
