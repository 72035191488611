<template>
  <div class="header">
    <div class="top">
      <div class="top-container">
        <div>欢迎访问透明云供应链管理平台！</div>
        <div class="right"><img src="../../assets/img/tel-icon.png" alt="">6361 1240</div>
      </div>
    </div>
    <div class="bottom">
      <div class="logo">
        <router-link to="/home"><img src="../../assets/img/logo.png" alt=""></router-link>
      </div>
      <div class="nav-box">
        <div :class="['nav-item',curIndex===index?'selected':'']" v-for="(item,index) in navList" :key="index" @click="changeNav(index)">
          <div class="name">{{ item.name }}</div>
          <div class="en-name">{{ item.en }}</div>
          <div class="children-list" v-if="item.childrenList&&item.childrenList.length>0">
            <div class="child-name" v-for="(childitem,index) in item.childrenList" :key="index">{{ childitem.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "HeaderContainer",
  data() {
    return {
      navList: [{
        name: '首页',
        en: 'HOME'
      },{
        name: '产品介绍',
        en: 'PRODUCT',
        // childrenList: [
        //   {name: '供应链管理平台'},
        //   {name: '生产监管平台'},
        //   {name: '会员管理系统'},
        //   {name: '电商平台'},
        //   {name: 'OA管理系统'},
        // ]
      },{
        name: '客户案例',
        en: 'CASE',
      },{
        name: '解决方案',
        en: 'SOLUTION',
        // childrenList: [
        //   {name: '贸易商供应链解决方案'},
        //   {name: '产品介绍2'},
        // ]
      },{
        name: '关于我们',
        en: 'ABOUT US',
        childrenList: [
          {name: '关于我们'},
          {name: '联系我们'},
        ]
      }],
      curIndex: 0
    }
  },
  methods: {
    changeNav(index){
      this.curIndex = index
    }
  }
}
</script>

<style lang='less' scoped>
.header {
  .top {
    width: 100%;
    height: 30px;
    background-color: #fbfbfb;
    .top-container {
      width: 1220px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #969696;
      .right {
        img {
          width: 16px;
          height: 16px;
          vertical-align: bottom;
          margin-right: 10px;
        }
      }
    }
  }
  .bottom {
    width: 1220px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .logo {
      width: 330px;
      height: 100px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nav-box {
      height: 100%;
      display: flex;
      .nav-item {
        &:hover {
          .children-list {
            display: inline-block;
          }
        }
        height: 100%;
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        .name {
          font-size: 16px;
          color: #313131;
          margin: 15px;
        }
        .en-name {
          font-size: 10px;
          color: #969696;
        }
        &.selected {
          background-color: #0074ff;
          .name {
            color: #fff;
          }
          .en-name {
            color: #fff;
          }
        }
        .children-list {
          display: none;
          position: absolute;
          min-width: 100%;
          top: 100px;
          left: 0;
          z-index: 99;
          background-color: #fff;
          padding: 20px 0;
          border-radius: 0 0 5px 5px;
          .child-name {
            cursor: pointer;
            height: 40px;
            line-height: 40px;
            padding: 0 20px;
            font-size: 16px;
            color: #313131;
            text-decoration: none;
            white-space: nowrap;
            &:hover {
              color: #fff;
              background-color: #0074ff;
            }
          }
        }
      }
    }
  }
}
</style>
